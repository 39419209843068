<template>
  <TTView>
    <VRow>
      <VCol>
        <VCard>
          <VToolbar flat>
            <VBtn
              icon
              @click="$router.go(-1)"
            >
              <VIcon>fal fa-chevron-left</VIcon>
            </VBtn>

            <VToolbarTitle>Редактирование ответа</VToolbarTitle>

            <VSpacer />

            <VTooltip left>
              <template #activator="{ on, attrs }">
                <VBtn
                  icon
                  depressed
                  color="red"
                  small
                  class="mr-4"
                  v-bind="attrs"
                  v-on="on"
                  @click="removeAnswer(testId, questionId, answerId)"
                >
                  <VIcon small>
                    fal fa-trash
                  </VIcon>
                </VBtn>
              </template>

              <span>Удаление ответа</span>
            </VTooltip>
          </VToolbar>
          <VCardText>
            <VForm ref="questionForm">
              <VRow>
                <VCol cols="10">
                  <TextEditor
                    v-model="answer.originalText"
                    label="Текст"
                    class="mt-4"
                  />

                  <VExpansionPanels
                    v-if="answer.originalText"
                    class="my-4"
                  >
                    <VExpansionPanel>
                      <VExpansionPanelHeader>Текст (HTML)</VExpansionPanelHeader>
                      <VExpansionPanelContent>
                        <!-- eslint-disable vue/no-v-html -->
                        <TTTextarea
                          v-model="answer.originalText"
                        />
                        <!-- eslint-enable vue/no-v-html -->
                      </VExpansionPanelContent>
                    </VExpansionPanel>
                  </VExpansionPanels>

                  <VCheckbox
                    v-model="answer.right"
                    label="Верный"
                  />

                  <VBtn
                    depressed
                    color="primary"
                    @click="saveChoice(testId, questionId, answerId)"
                  >
                    Сохранить
                  </VBtn>
                </VCol>
              </VRow>
            </VForm>
          </VCardText>
        </VCard>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import TextEditor from '../../../TextEditor.vue';

export default {
  name: 'AnswerEdit',
  components: {
    TextEditor,
  },
  inject: ['Names'],
  data() {
    return {
      answer: {
        id: '',
        text: '',
        originalText: '',
        right: '',
      },
    };
  },
  computed: {
    testId() {
      return this.$route.params.testId;
    },
    questionId() {
      return this.$route.params.questionId;
    },
    answerId() {
      return this.$route.params.answerId;
    },
    topicId() {
      return this.$route.params.topicId;
    },
  },
  async created() {
    try {
      const answers = await this.getChoices(this.testId, this.questionId);
      const currentAnswer = answers.find((answer) => answer.id === parseInt(this.answerId, 10));

      if (currentAnswer) {
        this.answer = currentAnswer;
      } else {
        this.$di.notify.snackError('Не удалось найти ответ');
      }
    } catch (error) {
      this.$di.notify.errorHandler(error);
    }
  },
  methods: {
    getChoices(testId, questionId) {
      return this.$di.api.Talent.choicesGet({ testId, questionId });
    },
    async saveChoice(testId, questionId, answerId) {
      const payload = {
        testId,
        questionId,
        answerId,
        text: this.answer.originalText,
        right: this.answer.right,
      };

      try {
        await this.$di.api.Talent.choiceUpdate(payload);
        this.$di.notify.snackSuccess('Ответ успешно обновлён');
      } catch (error) {
        this.$di.notify.errorHandler(error);
      }
    },

    async removeAnswer(testId, questionId, answerId) {
      try {
        await this.$di.api.Talent.choiceDelete({ testId, questionId, answerId });
        this.$di.notify.snackSuccess('Ответ успешно удалён');

        this.$router.push({
          name: this.Names.R_TALENT_TEST_TOPIC_QUESTION_ANSWERS,
          params: { testId: this.testId, topicId: this.topicId, questionId: this.questionId },
        });
      } catch (error) {
        this.$di.notify.errorHandler(error);
      }
    },
  },
};
</script>
